<template>
    <b-card no-body class="mb-0">
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="New Receipt">
        <!-- BODY -->
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="submitReceipt"
        >

          <!-- Customer -->
          <b-form-group label="Customer *">
              <Select2 v-model="chosenCustomer" :settings="customers" @select="putItemCustomer($event)" />
            </b-form-group>
          <!-- AccountNo -->
           <b-form-group label="Account No *" label-for="AccountNo">
            <b-form-input v-model="AccountNo" placeholder="Account No" required />
          </b-form-group>
          <!-- Invoices -->
            <b-form-group label="Invoices *" label-for="chosenItem">
              <Select2 v-model="chosenItem" :settings="settings" @select="putItem($event)" />
            </b-form-group>

            <b-row>
                <b-col cols="10" class="text-center font-weight-bold">
                  No Invoice
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Actions
                </b-col>
              </b-row>
              <br>
          <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="10 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="removeRow(chosen.value)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancelSubmit()"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
        </b-card>
        </b-col>
        </b-row>
        </b-card>
  </template>
<script>
import {
    BFormGroup, BFormInput, BForm, BButton, BFormDatepicker, BFormFile, BSidebar, BCard, BCol, BRow, BListGroup, BListGroupItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Select2 from 'v-select2-component'
import Ripple from "vue-ripple-directive"
import { getToken } from '@/auth/utils'
import { ref } from "@vue/composition-api"

const jobNumber = ref('')
const customer = ref('')
const description = ref('')
const issuedDate = ref(null)
const startDate = ref(null)
const endDate = ref(null)
const issuedBy = ref('')
const chosenItem = ref(3)
const chosenCustomer = ref('')
const customerFilter = ''
const customerRef = ''
const attachments = ref(null)
const chosens = []
export default {
  components: {
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormDatepicker,
    BSidebar,
    BCard,
    Select2,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewcontractSidebarActive',
    event: 'update:is-add-new-contract-sidebar-active',
  },
  methods: {
    cancelSubmit() {
      this.$router.push({ name: "apps-receipts-list" })
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
    putItemCustomer({ id, text }) {
      this.chosens = []
      this.customerFilter = text
    this.customerRef = id
},
    putItem({ id, text }) {
  // Check if the item already exists in `chosens`
  const exists = this.chosens.some(item => item.value === id)

  // If it does not exist, add it
  if (!exists) {
    const newItem = {
      label: text,
      value: id,
      qty: 1,
      id: this.chosens.length > 0 ? this.chosens.length + 1 : this.chosens.length,
      shelf: "",
    }
    this.chosens.push(newItem)
  }
},
    async submitReceipt() {
        const userToken = getToken()
      const formData = new FormData()
      const invoiceIds = this.chosens.map(item => item.value)
      formData.append('CustomerId', this.customerRef)
      formData.append('time', moment().format('YYYY-MM-DD'))
      formData.append('AccountNo', this.AccountNo)
      formData.append('invoiceIds', invoiceIds)
      const headers = {
        'Content-Type': 'multipart/form-data',
        'X-Token-Access': `Bearer ${userToken}`,
      }
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/invoice_service/insertReceipt`,
          formData,
          { headers },
        )
        if (response.status === 200 && response.data.Status === 1) {
            this.resetForm()
            this.$router.push({ name: "apps-receipts-list" })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Receipt Created Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Create Receipt',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Submitting Receipt',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        console.log('Succeed')
      }
    },
    resetForm() {
      this.NamaPT = ''
      this.AlamatPT = ''
      this.AccountNo = ''
      this.chosens = []
    },
  },
    data() {
      const userToken = getToken()
        return {
      settings: {
        ajax: {
          url: `${process.env.VUE_APP_API_URL}/invoice_service/getReceiptInvoices`,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Token-Access', `Bearer ${userToken}`)
          },
          data: params => {
            const query = {
              search: params.term,
              customer: this.customerFilter,
              offset: 0,
              limit: 50,
            }

            // Query parameters will be ?search=[term]&type=public
            return query
          },
          processResults: response => {
            let resItems = response.Invoices
            resItems = resItems.map(item => {
            const noInv = item[1]
            const invMaster = item[0]
              item.text = `${noInv}`
              item.id = invMaster
              return item
            })
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: resItems,
            }
          },
        },
      },
      customers: {
        ajax: {
          url: `${process.env.VUE_APP_API_URL}/invoice_service/getCustomers`,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Token-Access', `Bearer ${userToken}`)
          },
          data: params => {
            const query = {
              search: params.term,
              offset: 0,
              limit: 50,
            }

            // Query parameters will be ?search=[term]&type=public
            return query
          },
          processResults: response => {
            let resItems = response.Customers
            resItems = resItems.map(item => {
            const customerAddress = item[2]
            const customerName = item[1]
            const customerId = item[0]
              item.text = `${customerName}`
              item.customerName = `${customerName}`
              item.customerAddress = customerAddress
              item.id = customerId
              return item
            })
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: resItems,
            }
          },
        },
      },
      customerFilter,
      customerRef,
      NamaPT: '',
      AlamatPT: '',
      AccountNo: '',
      chosens,
          chosenItem,
          chosenCustomer,
      jobNumber,
      customer,
      description,
      issuedDate,
      startDate,
      endDate,
      issuedBy,
      attachments,
    }
  },
}
</script>
